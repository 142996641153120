import { Integer, Property, String, Timestamp, } from "@bumblebee/core/buzz_model";
import { createProperty } from "./factory";
export const pages_properties = {
    path: createProperty({
        name: "path",
        property_type: new String(),
        nullable: false,
        table_name: "pages",
        column_name: "path",
        primary_key: true,
        unique: true,
        description: "",
    }),
};
export const anonymous_users_properties = {
    anonymous_id: createProperty({
        name: "anonymous_id",
        property_type: new Integer(),
        nullable: false,
        table_name: "anonymous_users",
        column_name: "anonymous_id",
        primary_key: true,
        unique: false,
        description: "",
    }),
};
export const utm_sources_properties = {
    utm_source: createProperty({
        name: "utm_source",
        property_type: new String(),
        nullable: false,
        table_name: "utm_sources",
        column_name: "utm_source",
        primary_key: true,
        unique: true,
        description: "",
    }),
};
export const utm_campaigns_properties = {
    utm_campaign: createProperty({
        name: "utm_campaign",
        property_type: new String(),
        nullable: false,
        table_name: "utm_campaigns",
        column_name: "utm_campaign",
        primary_key: true,
        unique: true,
        description: "",
    }),
};
export const utm_contents_properties = {
    utm_content: createProperty({
        name: "utm_content",
        property_type: new String(),
        nullable: false,
        table_name: "utm_contents",
        column_name: "utm_content",
        primary_key: true,
        unique: true,
        description: "",
    }),
};
export const page_visit_events_properties = {
    page_path: createProperty({
        name: "page_path",
        property_type: new String(),
        nullable: false,
        table_name: "page_visit_events",
        column_name: "page_path",
        primary_key: false,
        unique: false,
        description: "",
    }),
    anonymous_id: createProperty({
        name: "anonymous_id",
        property_type: new Integer(),
        nullable: false,
        table_name: "page_visit_events",
        column_name: "anonymous_id",
        primary_key: false,
        unique: false,
        description: "",
    }),
    utm_source: createProperty({
        name: "utm_source",
        property_type: new String(),
        nullable: true,
        table_name: "page_visit_events",
        column_name: "utm_source",
        primary_key: false,
        unique: false,
        description: "",
    }),
    utm_campaign: createProperty({
        name: "utm_campaign",
        property_type: new String(),
        nullable: true,
        table_name: "page_visit_events",
        column_name: "utm_campaign",
        primary_key: false,
        unique: false,
        description: "",
    }),
    utm_content: createProperty({
        name: "utm_content",
        property_type: new String(),
        nullable: true,
        table_name: "page_visit_events",
        column_name: "utm_content",
        primary_key: false,
        unique: false,
        description: "",
    }),
    timestamp: createProperty({
        name: "timestamp",
        property_type: new Timestamp(),
        nullable: false,
        table_name: "page_visit_events",
        column_name: "timestamp",
        primary_key: false,
        unique: false,
        description: "",
    }),
};
export const scrolled_1vh_events_properties = {
    page_path: createProperty({
        name: "page_path",
        property_type: new String(),
        nullable: false,
        table_name: "scrolled_1vh_events",
        column_name: "page_path",
        primary_key: false,
        unique: false,
        description: "",
    }),
    anonymous_id: createProperty({
        name: "anonymous_id",
        property_type: new Integer(),
        nullable: false,
        table_name: "scrolled_1vh_events",
        column_name: "anonymous_id",
        primary_key: false,
        unique: false,
        description: "",
    }),
    timestamp: createProperty({
        name: "timestamp",
        property_type: new Timestamp(),
        nullable: false,
        table_name: "scrolled_1vh_events",
        column_name: "timestamp",
        primary_key: false,
        unique: false,
        description: "",
    }),
};
export const properties = {
    pages_properties,
    anonymous_users_properties,
    utm_sources_properties,
    utm_campaigns_properties,
    utm_contents_properties,
    page_visit_events_properties,
    scrolled_1vh_events_properties,
};
export default properties;
