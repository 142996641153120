import { BuzzModel, Entity, Relationship } from "@bumblebee/core/buzz_model";
import dagre from "@dagrejs/dagre";
import { Edge, Node, Position } from "@xyflow/react";

import { NodeData, XYGraph } from "@/types";

import { isSameEntity } from ".";

let y = 0;
const nodeWidth = 160;
const nodeHeight = 80;

export const createNode = (
  model: BuzzModel,
  entity: Entity,
): Node<NodeData> => {
  return {
    id: entity.name,
    data: {
      label: entity.name,
      entity,
      relationships: {
        from: model.relationships
          .toArray()
          .filter((rel) => isSameEntity(rel.from.entity, entity)),
        to: model.relationships
          .toArray()
          .filter((rel) => isSameEntity(rel.to.entity, entity)),
      },
    },
    type: "custom",
    position: { x: 100, y: (y += 100) },
  };
};

export const createEdge = (rel: Relationship): Edge => {
  return {
    id: rel.name,
    source: rel.from.entity.name,
    target: rel.to.entity.name,
    sourceHandle: rel.name,
    targetHandle: rel.name,
    type: "custom",
    animated: true,
  };
};

enum LayoutDirection {
  TopToBottom = "TB",
  LeftToRight = "LR",
}

const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

export const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  direction: LayoutDirection = LayoutDirection.LeftToRight,
) => {
  const isHorizontal = direction === LayoutDirection.LeftToRight;
  dagreGraph.setGraph({ rankdir: direction, ranksep: 200, nodesep: 100 });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge: Edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const newNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    const newNode: Node = {
      ...node,
      targetPosition: isHorizontal ? Position.Left : Position.Top,
      sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    };

    return newNode;
  });

  return { nodes: newNodes, edges };
};

export const graphFromSchema = (model: BuzzModel): XYGraph => {
  const baseApiNodes: Node<NodeData>[] = model.entities
    .toArray()
    .map((t) => createNode(model, t));
  const baseApiEdges: Edge[] = model.relationships.toArray().map(createEdge);
  const layoutedElements = getLayoutedElements(baseApiNodes, baseApiEdges);
  return layoutedElements;
};
