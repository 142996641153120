import { Entity, Property, PropertyReference, Relationship, } from "@bumblebee/core/buzz_model";
import { CustomType, List } from "@bumblebee/core/gleam";
// utils to make instantiating classes more explicit, specifying all fields by
// key rather than positional arguments to the constructor.
// The Omit<Property, keyof CustomType> type is used to remove the keys that are
// inherited from the CustomType base interface, thus the arguments are only the
// keys of the Property class.
export const createProperty = (params) => {
    return new Property(params.name, params.property_type, params.nullable, params.table_name, params.column_name, params.primary_key, params.unique, params.description);
};
// similar functions for other classes...
export function createRelationship(params) {
    return new Relationship(params.name, params.from, params.to, params.relation_type, params.description);
}
export function createPropertyReference(params) {
    return new PropertyReference(params.entity, params.property);
}
export function createEntity(params) {
    return new Entity(params.name, List.fromArray(params.properties), params.description);
}
