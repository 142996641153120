import { buzzModel } from "@bumblebee/buzz-loader";
import { type BuzzModel } from "@bumblebee/core/buzz_model";
import {
  Edge,
  Node,
  OnEdgesChange,
  OnNodesChange,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";

import env from "@/env";
import { graphFromSchema } from "@/utils/graph";

interface ApiContextType {
  nodes: Node[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  onNodesChange: OnNodesChange<Node>;
  onEdgesChange: OnEdgesChange<Edge>;
  error: string | null;
  fetchData: () => Promise<void>;
  loadBuzzModel: (model: BuzzModel) => Promise<void>;
  resetApi: () => Promise<void>;
}

// Create the context initial value
const ApiContext = createContext<ApiContextType | undefined>(undefined);

// Create a provider component
export const ApiContextProvider = ({ children }: PropsWithChildren) => {
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);

  const [error, setError] = useState<string | null>(null);

  // TODO: Should we deprecate this?
  const fetchData = useCallback(async () => {
    const response = await fetch(
      `${env.NEXT_PUBLIC_API_BASE_URL}/semantic-layer`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // TODO: hook this up to the actual agent response
    // const apiSchema: APISchemaResponse = await response.json();
    const nextGraph = graphFromSchema(buzzModel);

    setNodes(nextGraph.nodes);
    setEdges(nextGraph.edges);

    setError(null);
  }, [setEdges, setNodes]);

  const loadBuzzModel = useCallback(
    async (model: BuzzModel) => {
      const nextGraph = graphFromSchema(model);
      setNodes(nextGraph.nodes);
      setEdges(nextGraph.edges);
    },
    [setNodes, setEdges],
  );

  const resetApi = useCallback(async () => {
    await fetch(`${env.NEXT_PUBLIC_API_BASE_URL}/reset`);
    setNodes([]);
    setEdges([]);
  }, [setNodes, setEdges]);

  return (
    <ApiContext.Provider
      value={{
        nodes,
        edges,
        setNodes,
        setEdges,
        onNodesChange,
        onEdgesChange,
        error,
        resetApi,
        loadBuzzModel,
        fetchData,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

// Create a custom hook to use the context
export const useApiContext = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a ApiContext");
  }
  return context;
};
