import { WSMessage } from "@bumblebee/common/types";
import {
  api_projection_edits_from_json,
  type ProjectionEdit$,
} from "@bumblebee/core/api/projection_edits";
import { buzz_model_from_json, BuzzModel } from "@bumblebee/core/buzz_model";
import { List } from "@bumblebee/core/gleam";
import * as result from "@bumblebee/core/gleam/result";
import { inspect } from "@bumblebee/core/gleam/string";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useApiContext } from "@/context/ApiContext";
import { useProjectionContext } from "@/context/ProjectionContext";
import env from "@/env";
import { CStates } from "@/lib/state-machine";

import { useAppContext } from "./AppContext";

type ConnectionStatus = "connecting" | "connected" | "disconnected" | "busy";

interface AgentContextType {
  messages: WSMessage[];
  sendMessage: (text: string) => void;
  connectionStatus: ConnectionStatus;
  resetConnection: () => Promise<void>;
  handoffToProjectionEditor: () => void;
}

// Create the context initial value
const AgentContext = createContext<AgentContextType | undefined>(undefined);

// Create a provider component
export const AgentContextProvider = ({ children }: PropsWithChildren) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [messages, setMessages] = useState<WSMessage[]>([]);
  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStatus>("disconnected");
  const [correlationId, setCorrelationId] = useState<string | null>(null);
  const { fetchData, resetApi, loadBuzzModel } = useApiContext();
  const { setProjectionEdits } = useProjectionContext();
  const { navigateToCState } = useAppContext();

  const parseAndLoadProjectionEdits = useCallback(
    (msg: WSMessage): { message: WSMessage; edits: ProjectionEdit$[] } => {
      if (msg.type !== "pull_projection") {
        return { message: msg, edits: [] };
      }

      const parsedPayload = api_projection_edits_from_json(msg.payload);

      if (!parsedPayload.isOk()) {
        console.error(
          "Error parsing projection edits:",
          result.unwrap_error(
            result.map_error(parsedPayload, inspect),
            "Uknown error",
          ),
        );
        return {
          message: {
            ...msg,
            type: "agent_error",
            payload: "Error receiving the projection edits",
          },
          edits: [],
        };
      }

      const projectionEdits = result.unwrap(
        result.map(parsedPayload, (x: List<ProjectionEdit$>) => x.toArray()),
        [],
      );
      return {
        message: {
          ...msg,
          payload: "Successfully received the projection edits",
        },
        edits: projectionEdits,
      };
    },
    [],
  );

  const handoffToProjectionEditor = useCallback(() => {
    navigateToCState(CStates.PROJECTION_EDITOR);
  }, [navigateToCState]);

  // Initialize Agent connection
  const createConnection = useCallback(() => {
    setConnectionStatus("connecting");
    let ws: WebSocket;
    try {
      ws = new WebSocket(env.NEXT_PUBLIC_AGENT_WS_URL);
      setSocket(ws);
    } catch (error) {
      console.error("Error creating websocket connection:", error);
      setConnectionStatus("disconnected");
      return;
    }

    // Connection opened
    ws.addEventListener("open", () => {
      setConnectionStatus("connected");
    });

    // Listen for messages
    ws.addEventListener("message", async (event) => {
      const message: WSMessage = JSON.parse(event.data);
      console.debug("Received message:", message);
      // only keep track of correlationId for questions
      switch (message.type) {
        case "agent_busy":
          setConnectionStatus("busy");
          break;
        case "agent_internal":
        case "agent_info":
        case "agent_error":
          setConnectionStatus("connected");
          setMessages((prevMessages) => [...prevMessages, message]);
          break;
        case "push_buzz_model": {
          setConnectionStatus("connected");
          const parsedModel = buzz_model_from_json(message.payload);
          if (parsedModel.isOk()) {
            loadBuzzModel(
              result.unwrap(
                parsedModel,
                new BuzzModel(new List(), new List(), ""),
              ),
            );
          } else {
            console.error(
              "Error parsing buzz model:",
              result.unwrap_error(
                result.map_error(parsedModel, inspect),
                "Unknown error",
              ),
            );
          }
          break;
        }
        case "pull_projection": {
          setConnectionStatus("connected");
          const { message: newMessage, edits } =
            parseAndLoadProjectionEdits(message);
          setProjectionEdits(edits);
          await fetchData();
          setMessages((prevMessages) => [...prevMessages, newMessage]);
          break;
        }
        case "agent_ask":
          setConnectionStatus("connected");
          setCorrelationId(message.correlation_id);
          setMessages((prevMessages) => [...prevMessages, message]);
          break;
        case "pull_data":
          setConnectionStatus("connected");
          await fetchData();
          break;
        default:
          console.debug("Ignored message:", message);
      }
      if (message.type === "agent_ask") {
        setCorrelationId(message.correlation_id);
      }
    });

    // Connection closed
    ws.addEventListener("close", () => {
      setConnectionStatus("disconnected");
    });

    // Cleanup on unmount
    return () => {
      ws.close();
    };
  }, [
    fetchData,
    setProjectionEdits,
    loadBuzzModel,
    parseAndLoadProjectionEdits,
  ]);

  // connect on mount
  useEffect(() => {
    createConnection();
  }, [createConnection]);

  // reset connection
  const resetConnection = useCallback(async () => {
    setConnectionStatus("connecting");
    setCorrelationId(null);
    setMessages([]);
    if (socket != null) {
      socket.close();
    }
    await resetApi();
    createConnection();
  }, [createConnection, socket, resetApi]);

  // Send message function
  const sendMessage = useCallback(
    (payload: string) => {
      if (socket && connectionStatus === "connected") {
        const message: WSMessage =
          correlationId == null
            ? // first message
              {
                correlation_id: crypto.randomUUID(),
                type: "user_message",
                payload,
              }
            : // replies
              {
                correlation_id: correlationId,
                type: "user_reply",
                payload,
              };

        socket.send(JSON.stringify(message));
        setMessages((prevMessages) => [...prevMessages, message]);
      } else {
        console.error("Cannot send message: not connected", socket);
      }
    },
    [socket, connectionStatus, correlationId],
  );

  return (
    <AgentContext.Provider
      value={{
        messages,
        sendMessage,
        connectionStatus,
        resetConnection,
        handoffToProjectionEditor,
      }}
    >
      {children}
    </AgentContext.Provider>
  );
};

// Create a custom hook to use the context
export const useAgentContext = () => {
  const context = useContext(AgentContext);
  if (context === undefined) {
    throw new Error("useAgentContext must be used within a AgentContext");
  }
  return context;
};
