import { OneToMany } from "@bumblebee/core/buzz_model";
import { anonymous_users, page_visit_events, pages, scrolled_1vh_events, utm_campaigns, utm_contents, utm_sources, } from "./entities";
import { createPropertyReference, createRelationship } from "./factory";
import { anonymous_users_properties, page_visit_events_properties, pages_properties, scrolled_1vh_events_properties, utm_campaigns_properties, utm_contents_properties, utm_sources_properties, } from "./properties";
const pages_to_page_visit_events = createRelationship({
    name: "pages_to_page_visit_events",
    from: createPropertyReference({
        entity: pages,
        property: pages_properties.path,
    }),
    to: createPropertyReference({
        entity: page_visit_events,
        property: page_visit_events_properties.page_path,
    }),
    relation_type: new OneToMany(),
    description: "",
});
const pages_to_scrolled_1vh_events = createRelationship({
    name: "pages_to_scrolled_1vh_events",
    from: createPropertyReference({
        entity: pages,
        property: pages_properties.path,
    }),
    to: createPropertyReference({
        entity: scrolled_1vh_events,
        property: scrolled_1vh_events_properties.page_path,
    }),
    relation_type: new OneToMany(),
    description: "",
});
const anonymous_users_to_scrolled_1vh_events = createRelationship({
    name: "anonymous_users_to_scrolled_1vh_events",
    from: createPropertyReference({
        entity: anonymous_users,
        property: anonymous_users_properties.anonymous_id,
    }),
    to: createPropertyReference({
        entity: scrolled_1vh_events,
        property: scrolled_1vh_events_properties.anonymous_id,
    }),
    relation_type: new OneToMany(),
    description: "",
});
const anonymous_users_to_page_visits = createRelationship({
    name: "anonymous_users_to_page_visit_events",
    from: createPropertyReference({
        entity: anonymous_users,
        property: anonymous_users_properties.anonymous_id,
    }),
    to: createPropertyReference({
        entity: page_visit_events,
        property: page_visit_events_properties.anonymous_id,
    }),
    relation_type: new OneToMany(),
    description: "",
});
const utm_sources_to_page_visit_events = createRelationship({
    name: "utm_sources_to_page_visit_events",
    from: createPropertyReference({
        entity: utm_sources,
        property: utm_sources_properties.utm_source,
    }),
    to: createPropertyReference({
        entity: page_visit_events,
        property: page_visit_events_properties.utm_source,
    }),
    relation_type: new OneToMany(),
    description: "",
});
const utm_campaigns_to_page_visit_events = createRelationship({
    name: "utm_campaigns_to_page_visit_events",
    from: createPropertyReference({
        entity: utm_campaigns,
        property: utm_campaigns_properties.utm_campaign,
    }),
    to: createPropertyReference({
        entity: page_visit_events,
        property: page_visit_events_properties.utm_campaign,
    }),
    relation_type: new OneToMany(),
    description: "",
});
const utm_contents_to_page_visit_events = createRelationship({
    name: "utm_contents_to_page_visit_events",
    from: createPropertyReference({
        entity: utm_contents,
        property: utm_contents_properties.utm_content,
    }),
    to: createPropertyReference({
        entity: page_visit_events,
        property: page_visit_events_properties.utm_content,
    }),
    relation_type: new OneToMany(),
    description: "",
});
export const relationships = {
    pages_to_page_visit_events,
    pages_to_scrolled_1vh_events,
    anonymous_users_to_scrolled_1vh_events,
    anonymous_users_to_page_visits,
    utm_sources_to_page_visit_events,
    utm_campaigns_to_page_visit_events,
    utm_contents_to_page_visit_events,
};
export default relationships;
