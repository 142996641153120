import { assertEnv } from "@bumblebee/common/utils";
import { buzz_model_from_json, BuzzModel, } from "@bumblebee/core/buzz_model";
import { List, Result } from "@bumblebee/core/gleam";
import {} from "@bumblebee/core/gleam/json";
import * as result from "@bumblebee/core/gleam/result";
import * as string from "@bumblebee/core/gleam/string";
import { PrismaClient } from "@prisma/client";
const prisma = new PrismaClient();
export class BuzzLoaderImpl {
    async Load() {
        try {
            // ensure required env vars are set
            assertEnv({
                DATABASE_URL: process.env["DATABASE_URL"],
            });
            const metadata = await prisma.metadata.findFirst();
            if (!metadata || !metadata.json) {
                throw new Error("BuzzModel metadata not found in database");
            }
            const metadataJson = metadata.json;
            const metadataJsonStr = JSON.stringify(metadataJson);
            const parsingResult = buzz_model_from_json(metadataJsonStr);
            const emptyBuzzModel = new BuzzModel(List.fromArray([]), List.fromArray([]), "");
            if (!parsingResult.isOk()) {
                throw new Error(result.unwrap_error(result.map_error(parsingResult, string.inspect), "Unknown error"));
            }
            const metadataBuzzModel = result.unwrap(parsingResult, emptyBuzzModel);
            return metadataBuzzModel;
        }
        catch (error) {
            if (error instanceof Error) {
                console.error("Error loading or parsing metadata:", error);
                throw new Error("Error loading BuzzModel: " + error.message);
            }
            else {
                console.error("Unknown error occurred:", error);
                throw new Error("Unknown error occurred while loading BuzzModel");
            }
        }
    }
}
export const buzzLoader = new BuzzLoaderImpl();
export default buzzLoader;
