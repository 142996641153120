import { Entity } from "@bumblebee/core/buzz_model";
import { createEntity } from "./factory";
import { anonymous_users_properties, page_visit_events_properties, pages_properties, scrolled_1vh_events_properties, utm_campaigns_properties, utm_contents_properties, utm_sources_properties, } from "./properties";
export var EntityName;
(function (EntityName) {
    EntityName["pages"] = "pages";
    EntityName["anonymous_users"] = "anonymous_users";
    EntityName["utm_sources"] = "utm_sources";
    EntityName["utm_campaigns"] = "utm_campaigns";
    EntityName["utm_contents"] = "utm_contents";
    EntityName["page_visit_events"] = "page_visit_events";
    EntityName["scrolled_1vh_events"] = "scrolled_1vh_events";
})(EntityName || (EntityName = {}));
// PAGES
export const pages = createEntity({
    name: EntityName.pages,
    properties: Object.values(pages_properties),
    description: "",
});
// ANONYMOUS_USERS
export const anonymous_users = createEntity({
    name: EntityName.anonymous_users,
    properties: Object.values(anonymous_users_properties),
    description: "",
});
// UTM_SOURCES
export const utm_sources = createEntity({
    name: EntityName.utm_sources,
    properties: Object.values(utm_sources_properties),
    description: "",
});
// UTM_CAMPAIGNS
export const utm_campaigns = createEntity({
    name: EntityName.utm_campaigns,
    properties: Object.values(utm_campaigns_properties),
    description: "",
});
// UTM_CONTENTS
export const utm_contents = createEntity({
    name: EntityName.utm_contents,
    properties: Object.values(utm_contents_properties),
    description: "",
});
// PAGE_VISIT_EVENTS
export const page_visit_events = createEntity({
    name: EntityName.page_visit_events,
    properties: Object.values(page_visit_events_properties),
    description: "",
});
// SCROLLED_1VH_EVENTS
export const scrolled_1vh_events = createEntity({
    name: EntityName.scrolled_1vh_events,
    properties: Object.values(scrolled_1vh_events_properties),
    description: "",
});
export const entities = {
    pages,
    anonymous_users,
    utm_sources,
    utm_campaigns,
    utm_contents,
    page_visit_events,
    scrolled_1vh_events,
};
export default entities;
